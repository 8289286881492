import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const Container = styled.div`
  display: grid;
  grid-gap: 16px;
  margin-bottom: 10px;
`;

export const Section = styled.section``;

export const RecentSearchItemContainer = styled.div`
  width: 100%;
  position: relative;
  height: fit-content;
  max-height: 592px;
  & > button {
    color: ${(props) => props.theme.palette.text.t1};
    position: absolute;
    right: 24px;
    z-index: 2;
    border-radius: 50%;
    height: 24px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    &:hover {
      background: ${(props) => props.theme.palette.background.bg3};
    }
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const RecentSearchItem = styled.div`
  padding: 16px 24px;
  cursor: pointer;
  font-size: 16px;
  color: ${(props) => props.theme.palette.text.t1};
  border-bottom: 1px solid ${(props) => props.theme.palette.divider.d1};
  display: flex;
  align-items: center;
  gap: 8px;
  & > p {
    // flex-grow: 1;
    max-width: 80%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &:hover {
    background: ${(props) => props.theme.palette.background.bg3};
  }
  @media (max-width: ${WIDTH.laptopMid}) {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 4px;
  }
`;

export const PreOrderTag = styled.div`
  height: 22px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.palette.warning.main};
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 4px;

  & > p {
    color: ${(props) => props.theme.palette.warning.main};
    font-size: 12px;
    line-height: 14px;
    font-family: Onest-SemiBold;
    white-space: no-wrap;
  }
`;
