import dynamic from 'next/dynamic';
import router from 'next/router';
import React, { memo, useEffect, useRef, useState } from 'react';
import {
  AutocompleteContainer,
  AutocompleteHead,
  AutocompleteList,
  DetailsContainer,
  ImageContainer,
  ImageTag,
  ListDetailsContainer,
  ListItem,
  ListTitle,
  PreReleasedTag,
  PriceValue,
  ProductRegion,
  SimpleText,
} from '../SearchAutocomplete/SearchAutocompleteStyle';
const Link = dynamic(() => import('next/link'), {
  ssr: false,
});

import { useTheme } from 'styled-components';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { useLocale } from '../../contexts/LocalizationContext';
import { useTrendingProducts } from '../../contexts/TrendingProductsContext';
import {
  displayCurrencyValue,
  getDiscount,
  getRegionFromRegionId,
} from '../../helper/helper';
import { useAppSelector } from '../../redux/hooks';
import { saveEventV3 } from '../../utils/eventTracking';
import {
  getLocalStorageExp,
  saveLocalStorageExp,
} from '../../utils/localStorageUtils';
import { WE_USER_EVENTS_SEARCH_PRODUCT_CLICKED } from '../../utils/we';
import { MaskIcon } from '../Icons/Icons';
import { exportPlatformIcon } from '../Icons/PlatformIcons';
import { DiscountTag, MrpTagSec } from '../OtherOffersRow/OtherOffersRowStyle';
import { addToRecentSearches } from '../SearchAutocomplete/SearchAutocomplete';
import {
  Container,
  PreOrderTag,
  RecentSearchItem,
  RecentSearchItemContainer,
  Section,
} from './RecentSearchesStyles';

interface RecentSearchesProsp {
  display: boolean;
  setDisplayRecentSearches: React.Dispatch<React.SetStateAction<boolean>>;
}

const RecentSearches: React.FC<RecentSearchesProsp> = ({
  display,
  setDisplayRecentSearches,
}) => {
  const theme = useTheme();
  const { messages } = useLocale();
  const { recent_searches_msg, trending_searches_msg, oos_msg } =
    messages || {};

  const { locationDetails } = useAppSelector((state) => state.global);

  const [recentSearches, setRecentSearches] = useState<null | {
    [key: string]: {
      title: string;
      image: string;
      isPreReleased: 0 | 1 | 2;
      releaseDate?: string;
    };
  }>(null);

  const { trendingProducts, fetchTrendingProducts } = useTrendingProducts();

  const recentSearchesRef = useRef<HTMLDivElement>(null);

  const { cleverTap } = useAnalytics();

  useEffect(() => {
    if (!trendingProducts) {
      fetchTrendingProducts();
    }
  }, [fetchTrendingProducts, trendingProducts]);

  useEffect(() => {
    const recentSearches: any = getLocalStorageExp('recentSearches');
    if (recentSearches) {
      setRecentSearches(JSON.parse(recentSearches));
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e: any) => {
    const { current: wrap } = recentSearchesRef;
    if (wrap && !wrap.contains(e.target)) {
      setDisplayRecentSearches(false);
    }
  };

  const handleDeleteRecentSearch = (key: string) => {
    setRecentSearches((prev) => {
      if (prev) {
        delete prev[key as keyof Object];
        saveLocalStorageExp('recentSearches', { ...prev }, 10080);
        return {
          ...prev,
        };
      } else {
        return null;
      }
    });
  };

  return (
    <>
      {((recentSearches && Object.keys(recentSearches).length > 0) ||
        (trendingProducts && trendingProducts?.length > 0)) && (
        <AutocompleteContainer
          ref={recentSearchesRef}
          style={{
            opacity: display ? 1 : 0,
            pointerEvents: display ? 'auto' : 'none',
          }}
        >
          <AutocompleteList>
            <Container>
              {recentSearches && Object.keys(recentSearches).length > 0 && (
                <Section>
                  <AutocompleteHead>{recent_searches_msg}</AutocompleteHead>
                  {Object.keys(recentSearches)
                    .slice(0, 5)
                    .map((key: string) => (
                      <RecentSearchItemContainer key={key}>
                        <RecentSearchItem
                          onClick={() => {
                            router.push('/' + key);
                            setDisplayRecentSearches(false);
                          }}
                        >
                          <p>{recentSearches[key as keyof object].title}</p>
                          {recentSearches[key as keyof object].isPreReleased &&
                          new Date() <
                            new Date(
                              recentSearches[key as keyof object].releaseDate!
                            ) ? (
                            <PreOrderTag>
                              <p>Pre-order</p>
                            </PreOrderTag>
                          ) : null}
                        </RecentSearchItem>

                        <button onClick={() => handleDeleteRecentSearch(key)}>
                          <MaskIcon
                            url="/icons/close-24.svg"
                            width="18px"
                            height="18px"
                            color={theme.palette.text.t1}
                            selected
                            margin="0 0"
                          />
                        </button>
                      </RecentSearchItemContainer>
                    ))}
                </Section>
              )}
              {trendingProducts && trendingProducts?.length > 0 && (
                <Section>
                  <AutocompleteHead>{trending_searches_msg}</AutocompleteHead>
                  {trendingProducts?.map((product, index) => {
                    let discount = product
                      ? getDiscount(product.mrp, product.price) ?? 0
                      : 0;

                    return (
                      <Link
                        href={`/${product.slug}`}
                        passHref
                        key={index}
                        prefetch={false}
                      >
                        <a
                          onClick={() => {
                            addToRecentSearches(product);
                            setDisplayRecentSearches(false);

                            WE_USER_EVENTS_SEARCH_PRODUCT_CLICKED(
                              {
                                productImage: product.image,
                                productName: product.title,
                                productPlatform: product.platform as string,
                                basePrice: product.price
                                  ? product.price.toString()
                                  : '',
                                productRegion: product?.regionName
                                  ? product?.regionName
                                  : getRegionFromRegionId(product.regionId),
                                searchQuery: '',
                                pageUrl: window.location.href,
                                currency: locationDetails.currency ?? '',
                              },
                              cleverTap
                            );

                            saveEventV3({
                              category: 'header',
                              action: 'click',
                              label: 'trending_search_click',
                              properties: product.slug,
                              value: [],
                              from: router,
                            });
                          }}
                        >
                          <ListItem key={index} tabIndex={0} selected={true}>
                            <ImageContainer>
                              <ImageTag src={product.image} />
                            </ImageContainer>
                            <DetailsContainer>
                              <ListTitle>{product.title}</ListTitle>
                              <ListDetailsContainer
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between',
                                  height: '70px',
                                }}
                              >
                                <ProductRegion>
                                  {product?.platform
                                    ? exportPlatformIcon(
                                        product.platform,
                                        '16px',
                                        '16px',
                                        '0 0 0 0'
                                      )
                                    : null}
                                  {product?.regionName
                                    ? product?.regionName
                                    : getRegionFromRegionId(product.regionId)}
                                  {product.isPreReleased ? (
                                    <PreReleasedTag>Pre-order</PreReleasedTag>
                                  ) : null}
                                </ProductRegion>

                                {product?.price ? (
                                  <>
                                    <div style={{ display: 'flex' }}>
                                      {/* <SimpleText>{from_msg} </SimpleText> */}
                                      <PriceValue>
                                        {displayCurrencyValue(
                                          product.price,
                                          locationDetails?.currency_symbol,
                                          locationDetails?.multiplier
                                        )}
                                      </PriceValue>
                                      {product?.price &&
                                      product?.mrp &&
                                      discount &&
                                      discount > 0 ? (
                                        <MrpTagSec>
                                          <s>
                                            {displayCurrencyValue(
                                              product.mrp,
                                              locationDetails?.currency_symbol,
                                              locationDetails?.multiplier
                                            )}
                                          </s>

                                          <DiscountTag>
                                            <p>~{discount && discount}% off</p>
                                          </DiscountTag>
                                        </MrpTagSec>
                                      ) : null}
                                      {false}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <SimpleText>{oos_msg}</SimpleText>
                                  </>
                                )}
                              </ListDetailsContainer>
                            </DetailsContainer>
                          </ListItem>
                        </a>
                      </Link>
                    );
                  })}
                </Section>
              )}
            </Container>
          </AutocompleteList>
        </AutocompleteContainer>
      )}
    </>
  );
};

export default memo(RecentSearches);
